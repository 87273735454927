<template>
  <div class="checkout-booking-page">
    <BackTitle confirm-click @click="goBack">Checkout</BackTitle>
    <Loader v-if="isLoading" color="primary" size="m" class="mx-auto" />
    <template v-else>
      <StripeElementPayment
        v-if="clientSecret"
        ref="paymentElement"
        :pk="stripePublishableKey"
        :elements-options="elementsOptions"
        :confirm-params="confirmParams"
        :stripe-account="stripeAccount"
        @loading="isSubmitting = $event"
      />
      <BookingExpiredCountdown
        v-if="booking && booking.expiresAt"
        :expires-at="booking.expiresAt"
        :isPaymentExpired.sync="isPaymentExpired"
        @expired="handleExpired"
      />
      <BookingPriceBreakdown
        :price-data="checkoutPrice"
        :players-count="+booking.playersCount"
        :pay-now="payNow"
        :product-name="product && product.name"
      />
    </template>
    <div class="mt-auto">
      <Button
        :is-block="['xs', 'sm'].includes($mq)"
        :isDisabled="isLoading"
        :isLoading="isSubmitting"
        @click="checkout"
      >
        Pay {{ totalPrice }}
      </Button>
    </div>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import { mapActions, mapState } from "vuex";
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import { getBookingPrice } from "@/helpers/utils";
import { BookingPricingTypeEnum } from "@/helpers/enums";
import BookingExpiredCountdown from "@/components/bookings/BookingExpiredCountdown";
import dialog from "@/plugins/dialog";
import BookingPriceBreakdown from "@/components/bookings/BookingPriceBreakdown.vue";

export default {
  name: "CheckoutBookingPage",
  components: {
    BookingPriceBreakdown,
    BookingExpiredCountdown,
    StripeElementPayment,
    BackTitle,
  },
  data() {
    return {
      BookingPricingTypeEnum,
      isLoading: false,
      isSubmitting: false,
      stripePublishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      confirmParams: {
        return_url: `${window.location.origin}${
          this.$router.resolve({ name: "BookingDetail" }).href
        }`,
      },
      isPaymentExpired: false,
    };
  },
  computed: {
    ...mapState({
      booking: (state) => state.bookings.editedItem,
      stripeAccount: (state) => state.venues.selectedVenue.stripeAccount,
      products: (state) => state.products.list,
    }),
    product() {
      return (
        this.products.find((item) => item.id === this.booking.productId) || {}
      );
    },
    unpaidPayment() {
      return this.booking?.checkoutInfo?.payments?.find(
        (payment) =>
          !payment.paid &&
          payment.paymentIntentId &&
          payment.paymentIntentClientSecret
      );
    },
    clientSecret() {
      return this.unpaidPayment?.paymentIntentClientSecret;
    },
    checkoutPrice() {
      if (!this.booking?.checkoutInfo) return null;

      return getBookingPrice(this.booking?.checkoutInfo);
    },
    payNow() {
      if (this.checkoutPrice) {
        if (this.checkoutPrice.depositPrice) {
          return this.checkoutPrice.depositPrice;
        } else if (this.unpaidPayment?.amountToCollect) {
          return this.unpaidPayment.amountToCollect;
        } else {
          return this.checkoutPrice.total - this.checkoutPrice.paid;
        }
      }
      return 0;
    },
    totalPrice() {
      if (this.payNow) {
        return `$${(this.payNow / 100).toFixed(2)}`;
      }
      return 0;
    },
    amountToCollect() {
      if (!this.unpaidPayment) {
        return null;
      }

      return this.unpaidPayment.amountToCollect;
    },
    elementsOptions() {
      return {
        clientSecret: this.clientSecret,
      };
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchBookingById(this.$route.params.id);
      if (!this.clientSecret) {
        await this.goBack();
      }
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchBookingById: "bookings/fetchBookingById",
    }),
    async checkout() {
      this.$refs.paymentElement.submit();
    },
    goBack() {
      this.$router.push({
        name: "BookingDetail",
      });
    },
    async handleExpired() {
      await dialog.alert({
        title: "Time for payment has expired",
        message: "Please, create another booking",
        okText: "OK",
      });
      await this.$router.push({
        name: "BookingDetail",
      });
    },
  },
};
</script>

<style lang="scss">
#stripe-payment-element-errors {
  color: $danger;
  margin-top: 12px;
}
</style>

<style lang="scss" scoped>
.checkout-booking-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;

  .checkout-box {
    background: $white;
    box-shadow: $box-shadow-small;
    border: 2px solid $secondary-400;
    border-radius: 8px;
    padding: 24px 16px;

    @media (min-width: $media-laptop) {
      max-width: 550px;
    }
  }
}
</style>
